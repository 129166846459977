<template>
  <v-card>
    <v-stepper alt-labels v-model="currentStep">
      <v-stepper-header>
        <v-stepper-step step="1"> Mappage des établissement </v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step step="2"> Mappage des classes </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="3"> Traitement des données </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <etablissement-mapping v-bind:loading="loading" @step-done="(nextStep) => mappingEstablishment(nextStep)" />
        </v-stepper-content>

        <v-stepper-content step="2">
          <classe-mapping v-bind:loading="loading" @step-done="(nextStep) => finalizeImport(nextStep)" />
        </v-stepper-content>

        <v-stepper-content step="3">
          <finishing-import :processing="loading" />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-card>
</template>

<script>
import EtablissementMapping from "./EtablissementMapping.vue";
import ClasseMapping from "./ClasseMapping.vue";
import FinishingImport from "./FinishingImport.vue";
import ENTService from "../../services/ENTService";
import AdminService from "../../services/AdminService";

export default {
  components: {
    EtablissementMapping,
    ClasseMapping,
    FinishingImport,
  },
  data: () => ({
    currentStep: 1,
    loading: false,
  }),

  mounted() {
    this.currentStep = this.$route.query.step ?? 1;
  },

  computed: {},

  methods: {
    displayErrorMessage() {
      this.$store.dispatch("setSnackbar", {
        color: "error",
        text: "Une erreur est survenue",
      });
    },

    async mappingEstablishment(etablissements) {
      // this.loading = true;
      // AdminService.mapEtablissement(etablissements)
      //   .then((result) => {
      //     // Store the current establishment mapping
      console.log(etablissements)
      localStorage.setItem(
        "establishmentMapping",
        JSON.stringify((await Promise.all(etablissements
          .filter((item, index) => etablissements.findIndex((etb => etb.id === item.id)) === index)
          .map((etb) => AdminService.getEtablissementByID(etb.id)
          )
        )).map((result) => result.data)
        )
      );

      this.$router.push({ query: { step: 2 } });
      //   })
      //   .catch(() => {
      //     this.displayErrorMessage();
      //   })
      //   .finally(() => (this.loading = false));
    },

    finalizeImport(classes) {
      // const mappingEtb = JSON.parse(localStorage.getItem("establishmentMapping"))
      this.loading = true;
      const entData = JSON.parse(localStorage.getItem("EdData"))
      // entData.etablissements.forEach((etb) => {
      //   const mappedId = mappingEtb.find((mapEtb) => mapEtb.idsEcoleDirecte.indexOf(etb.id) >= 0).id
      //   if (etb.idDivalto) {
      //     etb.idDivalto.push(mappedId)
      //   } else {
      //     etb.idDivalto = [mappedId]
      //   }
      // })
      AdminService.createEntClasses(classes)
        .then(async () => {
          try {
            await ENTService.finalizeImport(
              entData
            );
            this.$router.push({ query: { step: 3 } });
            localStorage.removeItem("EdData");
            localStorage.removeItem("establishmentMapping");
          } catch {
            this.displayErrorMessage();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped></style>
