import http from "/http-common"; ///axios

class ENTService {
  options = {
    headers: {
      'Content-Type':'application/json'
    }
  };

	getEtablissement(idEtablissement) {
    return http.get(`gestionnaire/ent/${idEtablissement}`);
  }

  putEtablissement(idEtablissement, etablissementAPIData) {
    const etablissement = JSON.stringify(etablissementAPIData)
    return http.put(`gestionnaire/ent/${idEtablissement}`, etablissement, this.options)
  }

  refreshDataEnt(apiKey, classes) {
		return http.post(`/gestionnaire/ent?apiKey=${apiKey}`, JSON.stringify(classes), this.options);
	}

  finalizeImport(entData) {
    return http.post(`gestionnaire/ent`, entData, this.options)
  }
  
}

export default new ENTService();
