<template>
  <div class="ent-import-loader">
    <p>L'import des données ENT est terminé.</p>
    <div class="back-button">
      <v-btn block color="primary" @click="backToEtablissementTab">Retourner à l'onglet établissement</v-btn>
    </div>
  </div>
</template>

<script>
export default {
    methods: {
      backToEtablissementTab() {
        this.$router.push({name: "Etablissement"})
      }
    }
}
</script>

<style lang="scss" scoped>
.ent-import-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.back-button {
  width: 30%;
}
</style>
