<template>
  <v-card height="100%" color="backgroundColor">
    <PreMappingTab />
  </v-card>
</template>

<script>
import PreMappingTab from "../components/PreMappingComponent/PreMappingTab.vue";
export default {
  components: {
    PreMappingTab,
  },
};
</script>
