<template>
  <v-data-table :headers="headers" :items="etablissementsEnt" item-key="name" class="elevation-1 ma-4 pa-4"
    mobile-breakpoint="850">
    <template v-slot:top>
      <v-toolbar-title class="mx-4" v-if="!$vuetify.breakpoint.smAndUp">
        Établissements
      </v-toolbar-title>
      <v-toolbar flat>
        <v-toolbar-title v-if="$vuetify.breakpoint.smAndUp">
          Établissements
        </v-toolbar-title>
        <v-divider class="mx-4" inset vertical v-if="$vuetify.breakpoint.smAndUp" />
        <v-spacer v-if="$vuetify.breakpoint.smAndUp" />
        <v-spacer />
        <NextButton width="auto" color="primary" text="Valider" :loading="loading" @clicked="validerEtablissement">
        </NextButton>
      </v-toolbar>
    </template>
    <template v-slot:item.idDivalto="{ item }">
      <v-autocomplete v-model="item.idDivalto" :items="etablissementsArbs" item-text="displayText" item-value="id"
        multiple>
        
      </v-autocomplete>
    </template>
  </v-data-table>
</template>

<script>
import NextButton from "../Utility/NextButton.vue";
import AdminService from "../../services/AdminService";

export default {
  components: {
    NextButton,
  },
  props: ['loading'],
  data: () => ({
    headers: [
      { text: "Nom ENT", value: "libelle" },
      { text: "Etablissement ARBS", value: "idDivalto" },
    ],
    etablissementsArbs: [],
    etablissementsEnt: [],
  }),

  mounted() {
    this.initialize();
  },
  computed: {},

  methods: {
    initialize() {
      const etablissements = JSON.parse(
        localStorage.getItem("EdData")
      ).etablissements;

      AdminService.getEtablissementIds().then((etbs) => {
        const etablissementsArbs = etbs.data;
        for (let etbArbs of etablissementsArbs) {
          etbArbs.displayText = `${etbArbs.id} - ${etbArbs.nom}`;
        }
        // for (let etb of etablissements) {
          // trying to match already mapped etablissement
          // etb.codeDivalto = etablissementsArbs
          //   .filter((etbDivalto) => {
          //     return etbDivalto.idsEcoleDirecte.indexOf(etb.id) >= 0;
          //   })
          //   .map((matchedEstablishment) => matchedEstablishment.id)
        // }
        this.etablissementsEnt = etablissements;
        this.etablissementsArbs = etablissementsArbs;
      });
    },
    validerEtablissement() {
      const mappedEtablissements = []
      this.etablissementsEnt.forEach((etbEnt) => {
        if(etbEnt.idDivalto) {
          etbEnt.idDivalto.forEach((codeDvt) => {
            const etbArbs = this.etablissementsArbs.find((etbArbs) => etbArbs.id === codeDvt)
            if (etbArbs) {
                mappedEtablissements.push(etbArbs)
            } else {
              console.error("Etablissement not found")
            }
          })
        }
      })


      let edData = JSON.parse(localStorage.getItem("EdData"))
      edData.etablissements = this.etablissementsEnt
      localStorage.setItem("EdData", JSON.stringify(edData))


      this.$emit('step-done', mappedEtablissements)
    },
  },
};
</script>
<style lang="scss" scoped></style>