<template>
  <v-data-table
    :headers="headers"
    :items="classesEnt"
    item-key="name"
    class="elevation-1 ma-4 pa-4"
    mobile-breakpoint="850"
  >
    <template v-slot:top>
      <v-toolbar-title class="mx-4" v-if="!$vuetify.breakpoint.smAndUp"
        >Classes
      </v-toolbar-title>
      <v-toolbar flat>
        <v-toolbar-title v-if="$vuetify.breakpoint.smAndUp"
          >Classes</v-toolbar-title
        >
        <v-divider
          class="mx-4"
          inset
          vertical
          v-if="$vuetify.breakpoint.smAndUp"
        ></v-divider>
        <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
        <v-spacer></v-spacer>
        <NextButton
          width="auto"
          color="primary"
          text="Valider"
          :loading="loading"
          @clicked="validate"
        ></NextButton>
      </v-toolbar>
    </template>
    <template v-slot:item.displayEstablishment="{ item }">
      <v-select
        :items="item.etablissementsPossibles"
        item-text="displayText"
        item-value="id"
        v-model="item.idDivalto"
        @change="(value) => changeEtablissement(value, item)"
      />
    </template>
    <template v-slot:item.niveau="{ item }">
      <v-select
        clearable
        :items="classesArbs[item.idDivalto]"
        v-model="item.niveau"
      ></v-select>
    </template>
  </v-data-table>
</template>

<script>
import NextButton from "../Utility/NextButton.vue";
import AdminService from "../../services/AdminService";

export default {
  props: ['loading'],
  components: {
    NextButton,
  },
  data: () => ({
    headers: [
      { text: "Établissement", value: "displayEstablishment" },
      { text: "Code", value: "libelle" },
      { text: "Niveau", value: "niveau" },
    ],
    classesEnt: [],
    classesArbs: {},
  }),
  computed: {},
  mounted() {
    this.initialize();
  },

  methods: {
    initialize() {
      const etablissements = JSON.parse(
        localStorage.getItem("establishmentMapping")
      );
      AdminService.getClassesByEtablissementIds(
        etablissements.map((etb) => etb.id)
      ).then((response) => {
        const alreadyMappedClasses = response.data
        const edData = JSON.parse(localStorage.getItem("EdData"))
        const mappedEtablissements = edData.etablissements.filter((etb) => etb.idDivalto && etb.idDivalto.length > 0)
        const classes = edData.classes.filter((classe) => mappedEtablissements.find((etb) => etb.id === classe.idEtablissement)
        );
        this.classesArbs = etablissements.reduce((map, etablissement) => {
          map[etablissement.id] = etablissement.classes.sort();
          return map;
        }, {});

        classes.forEach((classe) => {
          const matchClasse = alreadyMappedClasses.find(
            (c) => {
              return classe.id === c.idEd
            }
          );
          classe.etablissementsPossibles = etablissements;
          if (matchClasse) {
            classe.idClasse = matchClasse.idClasse;
            const alreadyMappedEtablissement = matchClasse.etablissementEntity
            classe.displayEstablishment = `${alreadyMappedEtablissement.id} - ${alreadyMappedEtablissement.nom}`
            classe.idDivalto = alreadyMappedEtablissement.id
            classe.etablissementEntity = alreadyMappedEtablissement
            classe.niveau = matchClasse.niveau
          } else {
            classe.displayEstablishment =
              classe.etablissementsPossibles[0].displayText;
            classe.idDivalto = classe.etablissementsPossibles[0].id;
            classe.etablissementEntity = classe.etablissementsPossibles[0];
            classe.niveau = classe.etablissementEntity.classesEd.find(
              (clEd) => clEd.idEd === classe.id
            )?.niveau;
          }
        });
        this.classesEnt = classes;
      });
    },
    changeEtablissement(selected, item) {
      item.etablissementEntity = item.etablissementsPossibles.find(
        (etb) => etb.id === selected
      );
      item.niveau = undefined;
    },

    validate() {
      this.$emit('step-done', this.classesEnt)
    },
  },
};
</script>

<style scoped></style>
